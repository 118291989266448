import { ENV } from "@config/ENV.config";
import currenciesList from "@data/currency-details.json";
import { LocalStorageService } from "@services/utils/localsStorage.service";
import { isObject, isValidDate, nameMapper } from "utils";

import {
  currencyCountingOptions,
  currencySeparatorOptions,
  fileSizeOptions,
  weekStartDayOptions,
} from "@data/common.data";
import { Constant } from "./constant";
import { generateDateFormat } from "./date.utils";

const defaultSystemSettings = {
  country_phone_code: "+880",
  token_lifetime: 10,
  timezone: {
    name: "Asia/Dhaka",
    value: "Asia/Dhaka",
  },
  date_format: {
    name: "mm/dd/yyyy",
    value: "mm/dd/yyyy",
  },
  time_format: {
    name: "12 hr",
    value: "12 hr",
  },
  currency_type: {
    name: "BDT",
    value: "BDT",
  },
  currency_precision: {
    name: 1,
    value: 1,
  },
  currency_symbol_placement: {
    name: "Before",
    value: "Before",
  },
  currency_separator: { value: ",", label: "Comma (,)", meta: "COMMA" },
  currency_counting: { value: "crores", label: "Crores (1,00,00,000)", meta: "CRORES" },
  language: {
    name: "en",
    value: "en",
  },
  notification: {
    birthday: true,
    new_joining: false,
  },
  file_types: [
    {
      value: "pdf",
      name: "pdf",
    },
  ],
  file_size: {
    value: "1.3",
  },
  image_types: [
    {
      value: "jpeg",
      name: "jpeg",
    },
    {
      value: "png",
      name: "png",
    },
    {
      value: "jpg",
      name: "jpg",
    },
  ],
  image_size: {
    value: "3.2",
  },
  // TODO :: temporary used data limit
  data_limit: {
    id: "1",
    name: "limit",
    value: "10",
    label: "10_items",
  },
};

// IIFE used to store default system setting in local storage if it's not available
(() => {
  const localData = LocalStorageService.get("systemSettings");
  if (!localData) {
    LocalStorageService.set("systemSettings", defaultSystemSettings);
    window.dispatchEvent(new Event("storage"));
  }
})();

export const systemDate = (date: any) => {
  try {
    const systemSettingsOptions = LocalStorageService.get("systemSettings");
    const { date_format } = systemSettingsOptions;

    if (date && !isValidDate(date)) date = new Date(date);
    if (!isValidDate(date)) return Constant.NO_DATA;
    return generateDateFormat(date, isObject(date_format) ? date_format?.value : date_format);
  } catch (error) {
    ENV.env === "development" && console.error(error);
    return Constant.NO_DATA;
  }
};

// export const systemTime = (time: any) => {
//   try {
//     if (!time) return Constant.NO_DATA;
//     const systemSettingsOptions = LocalStorageService.get("systemSettings");
//     const { timeZone, time_format } = systemSettingsOptions;
//     const hour12 = (isObject(time_format) ? time_format.value : time_format) === "24 hr" ? false : true;
//     const providedTime = new Date(time);
//     const now = new Date();
//     let formattedTime: string = "";
//     const givenTimeParts = time.split(":");

//     console.log(providedTime);

//     if (!isValidDate(providedTime)) {
//       // Set time components to the given time
//       now.setHours(parseInt(givenTimeParts[0], 10));
//       now.setMinutes(parseInt(givenTimeParts[1], 10));
//       now.setSeconds(parseInt(givenTimeParts[2], 10));
//       console.log(now);

//       // Format the time in the desired format
//       formattedTime = now.toLocaleTimeString("en-US", {
//         hour12: hour12,
//         hour: "numeric",
//         minute: "numeric",
//         timeZone: isObject(timeZone) ? timeZone.value : timeZone,
//       });
//     } else {
//       formattedTime = providedTime.toLocaleTimeString("en-US", {
//         hour12: hour12,
//         hour: "numeric",
//         minute: "numeric",
//         timeZone: isObject(timeZone) ? timeZone.value : timeZone,
//       });
//     }

//     return formattedTime;
//   } catch (error) {
//     ENV.env === "development" && console.error(error);
//     return null;
//   }
// };

export const systemTime = (time: any): string | null => {
  try {
    if (!time) return Constant.NO_DATA;

    const systemSettingsOptions = LocalStorageService.get("systemSettings");
    const { timeZone, time_format } = systemSettingsOptions;
    const hour12 = (isObject(time_format) ? time_format.value : time_format) === "24 hr" ? false : true;

    let providedTime: Date;
    let formattedTime: string = "";

    // Check if time is a timestamp
    if (typeof time === "number" || !isNaN(Date.parse(time))) {
      providedTime = new Date(time);
    }
    // Handle HH:MM:SS format
    else if (typeof time === "string" && /^\d{1,2}:\d{2}(:\d{2})?$/.test(time)) {
      const now = new Date();
      const givenTimeParts = time.split(":");
      now.setHours(parseInt(givenTimeParts[0], 10));
      now.setMinutes(parseInt(givenTimeParts[1], 10));
      now.setSeconds(parseInt(givenTimeParts[2] || "0", 10));
      providedTime = now;
    } else {
      throw new Error("Invalid time format");
    }

    // Validate the date object
    if (!isValidDate(providedTime)) {
      throw new Error("Invalid date object");
    }

    // Format the time
    formattedTime = providedTime.toLocaleTimeString("en-US", {
      hour12: hour12,
      hour: "numeric",
      minute: "numeric",
      timeZone: isObject(timeZone) ? timeZone.value : timeZone,
    });

    return formattedTime;
  } catch (error) {
    if (ENV.env === "development") console.error(error);
    return null;
  }
};

export const systemDateTime = (timeStamp: any) => {
  if (!timeStamp) return;
  return systemDate(timeStamp) + ", " + systemTime(timeStamp);
};

// export const systemCurrency = (amount: string | number, symbol: boolean = false, onlyAmount: boolean = false) => {
//   try {
//     if (!amount) return Constant.NO_DATA;

//     amount = systemCurrencyPrecision(Number(amount));
//     const {
//       currency_type: { value: currency = "BDT" },
//       currency_symbol_placement: { value: placement = "After" },
//       currency_separator: { value: separator = "" } = {},
//       currency_counting: { value: counting = "" } = {},
//     } = LocalStorageService.get("systemSettings");

//     const { symbol_native: currency_symbol = "৳" } = currenciesList[currency];

//     if (counting && separator) {
//       // amount with separator
//       amount = systemCurrencySeparator(Number(amount), separator, counting);
//     }

//     if (!onlyAmount) {
//       if (placement == "Before") {
//         if (symbol) {
//           amount = currency_symbol + " " + amount || "";
//         } else {
//           amount = currency + " " + amount || "";
//         }
//       } else if (placement == "After") {
//         if (symbol) {
//           amount = amount + " " + currency_symbol || "";
//         } else {
//           amount = amount + " " + currency || "";
//         }
//       }
//     }

//     return amount;
//   } catch (error) {
//     ENV.env === "development" && console.error("Error:", error);
//     return Constant.NO_DATA;
//   }
// };

interface ISystemCurrency {
  amount: string | number;
  symbol?: boolean;
  onlyAmount?: boolean;
  notFound?: string;
}

export const systemCurrency = (
  argAmount: string | number | ISystemCurrency,
  argSymbol: boolean = false,
  argOnlyAmount: boolean = true,
  argNotFound?: string
) => {
  let amount, symbol, onlyAmount, notFound;

  if (typeof argAmount === "object" && isObject(argAmount)) {
    // Destructure the properties from the object argument
    ({ amount, symbol, onlyAmount, notFound } = argAmount);
  } else if (typeof argAmount === "string" || typeof argAmount === "number") {
    // Assign the individual arguments directly
    amount = argAmount;
    symbol = argSymbol;
    onlyAmount = argOnlyAmount;
    notFound = argNotFound;
  }

  try {
    if (!amount && isNaN(Number(amount))) return notFound != undefined ? notFound : Constant.NO_DATA;

    amount = systemCurrencyPrecision(Number(amount));
    const {
      currency_type: { value: currency = "BDT" },
      currency_symbol_placement: { value: placement = "After" },
      currency_separator: { value: separator = "" } = {},
      currency_counting: { value: counting = "" } = {},
    } = LocalStorageService.get("systemSettings");

    const { symbol_native: currency_symbol = "৳" } = currenciesList[currency];

    if (counting && separator) {
      // amount with separator
      amount = systemCurrencySeparator(Number(amount), separator, counting);
    }

    if (!onlyAmount) {
      if (placement == "Before") {
        if (symbol) {
          amount = currency_symbol + " " + amount || "";
        } else {
          amount = currency + " " + amount || "";
        }
      } else if (placement == "After") {
        if (symbol) {
          amount = amount + " " + currency_symbol || "";
        } else {
          amount = amount + " " + currency || "";
        }
      }
    }

    return amount;
  } catch (error) {
    ENV.env === "development" && console.error("Error:", error);
    return Constant.NO_DATA;
  }
};

export const systemCurrencyPrecision = (amount: number) => {
  try {
    if (isNaN(amount)) return Constant.NO_DATA;

    const {
      currency_precision: { value: precision = "2" },
    } = LocalStorageService.get("systemSettings");

    return amount.toFixed(precision);
  } catch (error) {
    ENV.env === "development" && console.error("Error:", error);
    return Constant.NO_DATA;
  }
};

export const systemCurrencySeparator = (
  amount: number | string,
  separator: string,
  countingStyle: "crores" | "millions"
) => {
  if (typeof amount !== "number") throw new Error("Amount must be a number");

  const isNegative = amount < 0;

  const absoluteAmount = Math.abs(amount); // Work with the absolute value for formatting

  const parts = String(absoluteAmount).split(".");

  let integerPart = parts[0];

  // console.log(amount);

  if (countingStyle === "crores") {
    const lastThreeDigits = integerPart.slice(-3); // Last 3 digits (hundreds)

    let middleDigits, restOfTheNumber;

    if (String(integerPart).length == 6) {
      middleDigits = integerPart.slice(-5, -3); // Next 4 digits (thousands and lacs)
      restOfTheNumber = integerPart.slice(0, -5);
    } else {
      middleDigits = integerPart.slice(-7, -3); // Next 4 digits (thousands and lacs)
      restOfTheNumber = integerPart.slice(0, -7); // Remaining digits (crores)
    }
    // const middleFourDigits = integerPart.slice(-7, -3); // Next 4 digits (thousands and lacs)

    // Step 1: Format middle digits (thousands and lacs)
    const formattedMiddle = middleDigits ? middleDigits.replace(/(\d{2})(?=\d)/g, `$1${separator}`) : "";

    // Step 2: Combine all parts
    const formattedAmount = [
      restOfTheNumber, // Crores (if exists)
      formattedMiddle, // Thousands and lacs (if exists)
      lastThreeDigits, // Hundreds
    ]
      .filter(Boolean) // Remove empty parts (e.g., if there are no crores)
      .join(separator); // Join with the separator

    integerPart = formattedAmount;
  } else if (countingStyle === "millions") {
    // Standard western format (group by 3 digits)
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  } else {
    throw new Error("Invalid counting style. Use 'millions' or 'crores'.");
  }

  // Append the decimal part if present
  const returnAmount = parts[1] ? `${integerPart}.${parts[1]}` : integerPart;

  return (isNegative ? "-" : "") + returnAmount;
};

export const systemSettingsMapper = (payload: any) => {
  const {
    contact_person,
    image_size,
    time_format,
    file_size,
    date_format,
    timezone,
    language,
    currency_type,
    currency_precision,
    currency_separator,
    currency_counting,
    currency_symbol_placement,
    week_start_day,
  } = payload;

  if (contact_person) payload.contact_person.name = nameMapper(contact_person);

  payload.country_phone_code = "+880";
  if (!isObject(language))
    payload.language = {
      name: language == "ar" ? "عربي" : language == "en" ? "English" : language == "bn" ? "বাংলা" : language,
      value: language,
    };

  if (week_start_day || week_start_day > -1)
    payload.week_start_day = weekStartDayOptions.find((item) => item?.value === week_start_day);

  if (image_size) payload.image_size = fileSizeOptions?.find((item) => item?.value === payload.image_size);
  if (file_size) payload.file_size = fileSizeOptions?.find((item) => item?.value === payload.file_size);

  if (timezone)
    payload.timezone = {
      name: timezone,
      value: timezone,
    };
  if (date_format)
    payload.date_format = {
      name: date_format,
      value: date_format,
    };
  if (time_format)
    payload.time_format = {
      name: time_format,
      value: time_format,
    };
  if (currency_type)
    payload.currency_type = {
      name: currency_type,
      value: currency_type,
    };
  if (currency_precision)
    payload.currency_precision = {
      name: currency_precision,
      value: currency_precision,
    };
  if (currency_symbol_placement)
    payload.currency_symbol_placement = {
      name: currency_symbol_placement,
      value: currency_symbol_placement,
    };
  if (currency_separator)
    payload.currency_separator = currencySeparatorOptions.find((item) => item.meta == payload.currency_separator);
  if (currency_counting)
    payload.currency_counting = currencyCountingOptions.find((item) => item.meta == payload.currency_counting);
  if (payload.file_types?.length) {
    payload.file_types = payload?.file_types?.map((item: any) => ({
      value: item,
      name: item,
    }));
  } else {
    payload.file_types = [];
  }

  if (payload.image_types?.length) {
    payload.image_types = payload?.image_types?.map((item: any) => ({
      value: item,
      name: item,
    }));
  } else {
    payload.image_types = [];
  }
  if (payload.attendance_types?.length) {
    payload.attendance_types = payload?.attendance_types?.map((item: any) => ({
      value: item,
      name: item,
    }));
  } else {
    payload.attendance_types = [];
  }
  return payload;
};

export const systemSettingsRequestData = (data: any) => {
  data.file_types = data?.file_types?.map((item: any) => item.value);

  data.image_types = data.image_types?.map((item: any) => item.value);

  data.timezone = data.timezone?.value;

  data.time_format = data.time_format?.value;
  data.date_format = data.date_format?.value;
  data.language = data.language?.value;
  data.image_size = data.image_size?.value;
  data.file_size = data.file_size?.value;
  data.currency_precision = data.currency_precision?.value;
  data.currency_symbol_placement = data.currency_symbol_placement?.value;
  data.currency_type = data.currency_type?.value;

  return data;
};

export const systemSettings = () => {
  return LocalStorageService.get("systemSettings") || defaultSystemSettings;
};

export const convertShiftRuleCalculationTime = (time: any) => {
  try {
    if (!time) return Constant.NO_DATA;
    const systemSettingsOptions = LocalStorageService.get("systemSettings");
    const { timeZone, time_format } = systemSettingsOptions;
    const hour12 = (isObject(time_format) ? time_format.value : time_format) === "24 hr" ? false : true;

    const timeParts = time.split(" ");
    const [hours, minutes, seconds] = timeParts[0].split(":").map(Number);
    const ampm = timeParts[1]?.toUpperCase();

    const providedDate = new Date("1970-01-01T00:00:00");
    providedDate.setHours((hours % 12) + (ampm === "PM" ? 12 : 0), minutes, seconds || 0);

    const formatter = new Intl.DateTimeFormat("en-US", {
      hour12: hour12,
      hour: "numeric",
      minute: "numeric",
      timeZone: isObject(timeZone) ? timeZone.value : timeZone,
    });

    return formatter.format(providedDate);
  } catch (error) {
    ENV.env === "development" && console.error(error);
    return null;
  }
};
