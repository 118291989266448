import EmailVerify from "@assets/images/emailVerifyImg.png";
import LinkBroken from "@assets/images/verificationLinkBroken.png";
import Button from "@components/Button/Button";
import EmailVerifySkeleton from "@components/Skeleton/EmailVerifySkeleton";
import { updateEmailVerifyContext } from "@pages/Authentication/UpdateEmail";
import { ButtonLoader } from "@services/utils/preloader.service";
import { useContext } from "react";
import { Link } from "react-router-dom";

const UpdateEmailVerify = () => {
  const { startSkeleton, userData, handleUpdateEmailVerify, handleRegenerateEmailLink, token, isLoading } =
    useContext(updateEmailVerifyContext);

  return (
    <div className="h-[80vh] flex justify-center items-center ">
      <div className="basis-6/12 flex justify-center items-center ">
        <img src={userData?.status === "NEW_LINK_REQUEST" ? LinkBroken : EmailVerify} alt="" className="" />
      </div>
      <div className="basis-6/12 ">
        {startSkeleton ? (
          <EmailVerifySkeleton />
        ) : (
          <div className="w-full">
            {userData?.status === "NEW_LINK_REQUEST" ? (
              <div className="w-full">
                <h3 className="mb-4 font-inter text-[28px] text-text-primary font-semibold">
                  Sorry ! Your verification link has been expired.
                </h3>
                <h5 className="mb-4 font-inter text-base text-text-primary font-medium">Hi, {userData?.user_name}</h5>
                <p className="mb-6 font-inter text-base text-text-primary w-2/3">
                  Your email can not be verified as you entered an expired link. You can send request for another link.
                </p>
                <Button disable={isLoading} onClick={() => handleRegenerateEmailLink(token)} color="primary" round="md">
                  {isLoading ? <ButtonLoader /> : "Request for New Link"}
                </Button>
              </div>
            ) : userData?.status === "VERIFIED_AND_UPDATED" ? (
              <>
                <h3 className="mb-3 font-inter text-[26px] text-text-primary font-semibold">
                  Your email has already been verified.
                </h3>
                <h5 className="mb-3 font-inter text-base text-text-primary font-medium">Hi, {userData?.user_name}</h5>
                <p className="mb-6 font-inter text-base text-text-primary w-2/3">
                  Your email has already been verified.Login into your account.
                </p>
                <Link to={"/login"}>
                  <Button color="primary" round="md">
                    Login into your account
                  </Button>
                </Link>
              </>
            ) : userData?.status === "LINK_VERIFIED" ? (
              <div className="space-y-3">
                <h5 className="mb-3 font-inter text-xl text-text-primary font-medium">
                  Hi, <span className="font-semibold">{userData?.user_name}</span>
                </h5>
                <p className="pb-5 font-inter text-base text-text-primary w-[75%]">
                  {" "}
                  We noticed you'd like to update your email address. To confirm this change, please click the button
                  below to verify your new email address.
                </p>

                <Button onClick={() => handleUpdateEmailVerify(token)}>
                  {isLoading ? <ButtonLoader /> : "Verify Now"}{" "}
                </Button>

                <p className="text-sm text-gray-500 pt-4 max-w-sm">
                  If you did not request this change, please ignore this email or contact our support team.
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateEmailVerify;
