import successImg from "@assets/images/emailSent.png";
import { ReactNode } from "react";

interface ISuccessCArd {
  status?: string;
  message?: string;
  children: ReactNode;
  img?: any;
}

const SuccessCard = ({ status, message, img, children }: ISuccessCArd) => {
  return (
    <div className="w-auto h-auto flex flex-col justify-center items-center">
      {img && <img src={img ? img : successImg} alt="" className="w-[60%]" />}

      <h4 className="mt-8 font-inter text-base font-medium text-neutral-500">{status}</h4>
      <h2 className="mt-1.5 mb-3 font-inter text-6xl font-semibold text-primary-700">{message}</h2>
      <div className="flex flex-col justify-center items-center">{children}</div>
    </div>
  );
};

export default SuccessCard;
