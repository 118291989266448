import assetsModule from "./assets.bn.json";
import attendanceModule from "./attendance.bn.json";
import BengaliTranslation from "./bn.json";
import dashboardTranslation from "./dashboard.bn.json";
import BnEmployeeTranslation from "./employee.bn.json";
import BnLabel from "./label.bn.json";
import leaveModule from "./leave.bn.json";
import payrolls from "./Payrolls/payrolls.bn.json";
import placeholder from "./placeholder.bn.json";
import reportsModule from "./reports.bn.json";
import { default as CommonBnTranslation, default as responseMessage } from "./response-message.bn.json";
import scheduleModule from "./schedule.bn.json";
import BnAccountSetting from "./settings/account-setting.bn.json";
import aclModule from "./settings/acl.bn.json";
import bankInfo from "./settings/bank-info.bn.json";
import facilitiesModule from "./settings/facilities.bn.json";
import hrManagement from "./settings/hr-management.bn.json";
import BnSystemSettings from "./settings/system-setting.bn.json";
import BnSidebarTranslation from "./sidebar.bn.json";
import validationMessage from "./validation.bn.json";

export const bn = {
  ...BengaliTranslation,
  ...BnSidebarTranslation,
  ...BnEmployeeTranslation,
  ...BnAccountSetting,
  ...BnLabel,
  ...BnSystemSettings,
  ...placeholder,
  ...CommonBnTranslation,
  ...attendanceModule,
  ...leaveModule,
  ...scheduleModule,
  ...assetsModule,
  ...bankInfo,
  ...facilitiesModule,
  ...aclModule,
  ...responseMessage,
  ...validationMessage,
  ...reportsModule,
  ...dashboardTranslation,
  ...hrManagement,
  ...payrolls,
};
