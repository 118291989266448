import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { BASIC_INFO, ONBOARD, STEP } from "./path-name.route";

const OnboardLayout = lazy(() => import("@layouts/OnboardLayout"));
const InitialOnboarding = lazy(() => import("@pages/Onboard/InitialOnboarding/InitialOnboarding"));
const OnboardingStep = lazy(() => import("@pages/Onboard/OnboardingStep/OnboardingStep"));
const OnboardDepartment = lazy(() => import("@pages/Onboard/OnboardingStep/Step/OnboardDepartment"));
const OnboardDesignation = lazy(() => import("@pages/Onboard/OnboardingStep/Step/OnboardDesignation"));
const OnboardDivision = lazy(() => import("@pages/Onboard/OnboardingStep/Step/OnboardDivision"));
const OnboardGrade = lazy(() => import("@pages/Onboard/OnboardingStep/Step/OnboardGrade"));
const OnboardSession = lazy(() => import("@pages/Onboard/OnboardingStep/Step/OnboardSession"));

const OnboardRoutes = () => {
  return (
    <Suspense>
      <Routes>
        <Route element={<OnboardLayout />}>
          <Route path="*" element={<Navigate to={ONBOARD + BASIC_INFO} />} />
          <Route path={ONBOARD + BASIC_INFO} element={<InitialOnboarding />} />
          <Route path={ONBOARD + STEP + "/*"} element={<OnboardingStep />}>
            <Route path={"*"} element={<Navigate to={ONBOARD + STEP + "/division"} replace />} />
            <Route path={"division"} element={<OnboardDivision />} />
            <Route path={"department"} element={<OnboardDepartment />} />
            <Route path={"grade"} element={<OnboardGrade />} />
            <Route path={"designation"} element={<OnboardDesignation />} />
            <Route path={"session"} element={<OnboardSession />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default OnboardRoutes;
