import PasswordUpdate from "@assets/images/PasswordUpdate.png";
import Logo from "@assets/images/rysenova-logo-v7_720.png";
import Button from "@components/Button/Button";
import SuccessCard from "@components/Card/SuccessCard";

import { useNavigate } from "react-router-dom";

const SuccessUpdateEmailVerify = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="relative">
        <div className="absolute top-10 left-10">
          <div className="">
            <img src={Logo} alt="" className="w-auto h-10" />
          </div>
        </div>
        <div className="flex justify-center items-center h-screen w-full">
          <SuccessCard img={PasswordUpdate} status="Congratulations" message="Your email is updated Successfully">
            <Button
              className="px-6 py-3 mt-6 justify-center"
              width="96"
              onClick={() => navigate("/login")}
              color="primary"
              variant="fill"
              round="md"
            >
              {/* {t("Sign IN")} */}
              Login into your account
            </Button>
          </SuccessCard>
        </div>
      </div>
    </>
  );
};

export default SuccessUpdateEmailVerify;
