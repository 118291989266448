import { combineReducers } from "@reduxjs/toolkit";
import disbursementReducer from "./reducers/disbursementReducer";
import reportReducer from "./reducers/reportReducer";
import scheduleReducer from "./reducers/scheduleReducer";
import userReducer from "./reducers/userReducer";

export interface SessionState {
  user: any;
  schedule: any;
  report: any;
  disbursement: any;
}

const rootReducer = combineReducers<SessionState>({
  user: userReducer,
  schedule: scheduleReducer,
  report: reportReducer,
  disbursement: disbursementReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
