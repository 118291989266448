import { ISettingsSalaryACL } from "@acl/acl.interfaces";
import { commonFieldAcl } from "@acl/modules";

export const salarySettingAcl: ISettingsSalaryACL = {
  parent: { action: "payroll-structures", resource: "payroll-structures" },
  salaryStructure: {
    ...commonFieldAcl("payroll-structures"),
    updateStatus: { action: "updateStatus", resource: "payroll-structures" },
  },
  components: {
    ...commonFieldAcl("payroll-components"),
    updateStatus: { action: "updateStatus", resource: "payroll-components" },
  },
};
