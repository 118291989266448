export const isBrowser = typeof window !== "undefined";
export const isServer = typeof window == "undefined";

interface IStorageService {
  // eslint-disable-next-line @typescript-eslint/ban-types
  set: Function;
  get: any;
  delete: any;
  clear: any;
}

export const LocalStorageService: IStorageService = {
  set: (key: string, value: any): void => localStorage.setItem(key, JSON.stringify(value)),
  get: (key: string): string | null => {
    const data = localStorage.getItem(key);
    if (data) return JSON.parse(data);
    return null;
  },
  delete: (key: string): void => localStorage.removeItem(key),
  clear: (): void => localStorage.clear(),
};

export const SessionStorageService: IStorageService = {
  set: (key: string, value: any): void => sessionStorage.setItem(key, JSON.stringify(value)),
  get: (key: string): string | null => {
    const data = sessionStorage.getItem(key);
    if (data) return JSON.parse(data);
    return null;
  },
  delete: (key: string): void => sessionStorage.removeItem(key),
  clear: (): void => sessionStorage.clear(),
};
