import ability from "@acl/ability";
import Icon from "@components/Icon/Icon";
import { LayoutContext } from "@layouts/AuthLayout";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { dataTestIdStringMod, isObject } from "utils";
import { INavChildren, INavLink } from "./nav-links";

interface INav {
  navLinks: INavLink & INavChildren;
  link?: string;
  routeIndex?: number;
  setRouteIndex?: Function;
  onClick?: Function;
  expandSidebar?: boolean;
  index?: number;
}

const NavLink = ({ navLinks, link = "", routeIndex, setRouteIndex, onClick, expandSidebar, index }: INav) => {
  const { pathname } = useLocation();

  const { tag } = navLinks;

  // Recursive condition: Check if the navLinks has children
  const hasChildren = navLinks.children && navLinks.children?.length > 0;

  const { t } = useTranslation();

  // get the current path from URL
  const currentPath = navLinks.link ? navLinks.link.split("/").slice(-1)[0].split("?")[0] : "";

  // to make nesting route path
  const currentNestedPath = link + navLinks.link ? navLinks.link : "";

  // const pathLink = (nav: INavLink) => {
  //   if (typeof nav.ownPage === "boolean" && !nav.ownPage) {
  //     return window.location.href;
  //   }
  //   return currentNestedPath;
  // };

  const { showSidebar, setShowSidebar } = useContext(LayoutContext);
  const isRouteActive = () => {
    return pathname.split("/").includes(currentPath) && !hasChildren;
  };

  // NOTE:: this checking for ACL routing
  if (navLinks.meta && !ability.can(navLinks.meta?.action, navLinks.meta?.resource)) return null;

  return (
    <li
      // onClick={() => onClick()}
      className={`nav-link ${isRouteActive() ? "active" : ""}`}
      data-test-id={
        hasChildren ? dataTestIdStringMod(navLinks.label) + "-label" : dataTestIdStringMod(navLinks.label) + "-nav"
      }
    >
      {navLinks.label !== "Settings" && index != 0 ? (
        <Link
          to={
            // if the route have own page then it will have that link otherwise it will have the current path
            // pathLink(navLinks)
            //   TODO:: uncommented for future reference
            //   navLinks.ownPage
            //     ? currentNestedPath
            //     : navLinks.children
            //     ? currentNestedPath + navLinks?.children[0].link
            //     : currentNestedPath
            currentNestedPath
          }
          className={`${expandSidebar ? "" : "flex lg:justify-center"} ${hasChildren ? "pointer-events-none" : ""}`}
          onClick={() => setShowSidebar(false)}
        >
          {navLinks.menuIcon ? (
            <img className={`w-[18px] h-w-5 ${isRouteActive() ? "is-active " : ""}`} src={navLinks.menuIcon} />
          ) : (
            navLinks.icon && <Icon name={navLinks.icon} variant="outlined" className="left-icon text-base" />
          )}
          <>
            <span className={`li-label text-sm whitespace-nowrap ${expandSidebar ? "" : "lg:hidden block"}`}>
              {t(navLinks.label)}
            </span>
            {hasChildren && isObject(tag) && expandSidebar ? (
              <span className={`tag text-[${tag?.color}] bg-[${tag?.background}] p-2`}>{tag?.label}</span>
            ) : null}

            {hasChildren && !expandSidebar ? <span className="li-label text-sm lg:block hidden">-</span> : null}
          </>

          {/* expandSidebar  */}
        </Link>
      ) : null}

      {hasChildren && (
        <ul className={`child-dropdown`}>
          {navLinks?.children?.map((child: INavChildren & INavLink, indx: number) => (
            <NavLink navLinks={child} key={indx} link={currentNestedPath} expandSidebar={expandSidebar} />
          ))}
        </ul>
      )}
    </li>
  );
};

export default NavLink;
