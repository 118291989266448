import ability from "./ability";
import { IActionResource } from "./acl.interfaces";
import { employeeACL } from "./modules/employee.data";
import { menuListCategories } from "./modules/menu.data";

export interface IPermissionACL {
  subject: string;
  action: string;
}
export const hasPermitted = (item: IActionResource | IActionResource[]) => {
  if (Array.isArray(item)) return item.some((item) => ability.can(item?.action, item?.resource));

  return ability.can(item?.action, item?.resource);
};

export const processingMenuPermission = (permissions: IPermissionACL[]) => {
  const data: any[] = [];
  permissions.forEach((permission: IPermissionACL) => {
    for (const [_, subData] of Object.entries<any>(menuListCategories)) {
      for (const [category, subSubData] of Object.entries<any>(subData)) {
        for (const [subCategory, { action, resource }] of Object.entries<IActionResource>(subSubData)) {
          if (
            subCategory === permission.subject &&
            !data.some((item) => item.subject === resource && item.action === action)
          ) {
            data.push({ subject: resource, action });
            // Note: No break statement here
          }
        }
      }
    }
  });
  const addParentMenu: any[] = [];
  const categories: any = menuListCategories;
  [...permissions, ...data].forEach((item) => {
    for (const [_, subCategory] of Object.entries<any>(categories)) {
      // console.log("🚀 ~ [...permissions,...data].forEach ~ subCategory:", subCategory);
      if (
        subCategory?.parent &&
        subCategory[item.subject] &&
        !addParentMenu.some(
          (item) => item.subject === subCategory.parent.resource && item.action === subCategory.parent.action
        )
      ) {
        addParentMenu.push({
          subject: subCategory.parent.resource,
          action: subCategory.parent.action,
        });
        break;
      } else {
        for (const [_, subSubData] of Object.entries<any>(subCategory)) {
          if (
            subSubData?.parent &&
            subSubData[item.subject] &&
            !addParentMenu.some(
              (item) => item.subject === subSubData.parent.resource && item.action === subSubData.parent.action
            )
          ) {
            // TODO::nested layer kind of main parent data handle
            if (
              subCategory.parent &&
              !addParentMenu.some(
                (item) => item.subject === subCategory.parent.resource && item.action === subCategory.parent.action
              )
            ) {
              addParentMenu.push({
                subject: subCategory.parent.resource,
                action: subCategory.parent.action,
              });
            }

            addParentMenu.push({
              subject: subSubData.parent.resource,
              action: subSubData.parent.action,
            });
            break;
          }
        }
      }
    }
  });
  const inputArray = [...data, ...addParentMenu, ...permissions];

  // Create a Set to keep track of unique keys
  const uniqueKeys = new Set();

  // Use the filter method to create a unique array of objects
  const uniqueArray = inputArray.filter((item) => {
    const uniqueKey = `${item.subject}-${item.action}`;
    if (!uniqueKeys.has(uniqueKey)) {
      uniqueKeys.add(uniqueKey);
      return true;
    }
    return false;
  });
  return uniqueArray;
};

export const permissionMapping = (permissions: IPermissionACL[]) => {
  const newPermissions: IPermissionACL[] = [];
  const employeeAcl = employeeACL.employee;

  for (let i = 0; i < permissions?.length; i++) {
    const item = permissions[i];
    // Check and add permissions for employee form
    if (addPermission(newPermissions, employeeAcl.general.form, item, hasEmployeeForm)) continue;
    // Check and add permissions for personal form
    if (addPermission(newPermissions, employeeAcl.personal.form, item, hasEmployeePersonalForm)) continue;
    // Check and add permissions for employment form
    if (addPermission(newPermissions, employeeAcl.employment.form, item, hasEmployeeEmploymentForm)) continue;
    // Check and add permissions for education form
    if (addPermission(newPermissions, employeeAcl.education.form, item, hasEducationForm)) continue;
    // Check and add permissions for training form
    if (addPermission(newPermissions, employeeAcl.training.form, item, hasTrainingForm)) continue;
    // Check and add permissions for histories form
    if (addPermission(newPermissions, employeeAcl.history.form, item, hasHistoryForm)) continue;
    // Check and add permissions for service book form
    if (addPermission(newPermissions, employeeAcl.serviceBook.form, item, hasServiceBookForm)) continue;
    // Check and add permissions for action form
    if (addPermission(newPermissions, employeeAcl.actions.form, item, hasActionForm)) continue;
    // Check and add permissions for achievement form
    if (addPermission(newPermissions, employeeAcl.achievement.form, item, hasAchievementForm)) continue;
    // Check and add permissions for address form
    if (addPermission(newPermissions, employeeAcl.address.form, item, hasAddressForm)) continue;
    // Check and add permissions for documents form
    if (addPermission(newPermissions, employeeAcl.document.form, item, hasDocumentForm)) continue;
    // Check and add permissions for family form
    if (addPermission(newPermissions, employeeAcl.family.form, item, hasFamilyForm)) continue;
    // Check and add permissions for bank form
    if (addPermission(newPermissions, employeeAcl.bank.form, item, hasBankInfoForm)) continue;

    // ... Repeat the pattern for other forms ...
  }

  // Combine new permissions with existing permissions
  return [...newPermissions, ...permissions];
};

const addPermission = (
  newPermissions: IPermissionACL[],
  formAcl: any,
  permission: IPermissionACL,
  checkFunction: (permission: IPermissionACL) => boolean
) => {
  if (!newPermissions.some((itm) => itm.subject === formAcl.action) && checkFunction(permission)) {
    newPermissions.push({
      action: formAcl.action,
      subject: formAcl.resource,
    });

    // If the permission was added, return true to indicate that the loop should continue
    return true;
  }

  // If the permission was not added, return false to indicate that the loop should not continue
  return false;
};

const hasEmployeeForm = (permission: IPermissionACL): boolean => {
  const general = employeeACL.employee.general;
  return (
    (permission.action === general.create.action && permission.subject === general.create.resource) ||
    (permission.action === general.update.action && permission.subject === general.update.resource)
  );
};

const hasEmployeePersonalForm = (permission: IPermissionACL): boolean => {
  const personalAcl = employeeACL.employee.personal;
  return (
    (permission.action === personalAcl.create.action && permission.subject === personalAcl.create.resource) ||
    (permission.action === personalAcl.update.action && permission.subject === personalAcl.update.resource)
  );
};

const hasEmployeeEmploymentForm = (permission: IPermissionACL): boolean => {
  const employmentAcl = employeeACL.employee.employment;
  return (
    (permission.action === employmentAcl.create.action && permission.subject === employmentAcl.create.resource) ||
    (permission.action === employmentAcl.update.action && permission.subject === employmentAcl.update.resource)
  );
};

const hasEducationForm = (permission: IPermissionACL): boolean => {
  const education = employeeACL.employee.education;
  return (
    (permission.action === education.create.action && permission.subject === education.create.resource) ||
    (permission.action === education.update.action && permission.subject === education.update.resource)
  );
};
const hasTrainingForm = (permission: IPermissionACL): boolean => {
  const trainingAcl = employeeACL.employee.training;
  return (
    (permission.action === trainingAcl.create.action && permission.subject === trainingAcl.create.resource) ||
    (permission.action === trainingAcl.update.action && permission.subject === trainingAcl.update.resource)
  );
};

const hasHistoryForm = (permission: IPermissionACL): boolean => {
  const acl = employeeACL.employee.history;
  return (
    (permission.action === acl.create.action && permission.subject === acl.create.resource) ||
    (permission.action === acl.update.action && permission.subject === acl.update.resource)
  );
};

const hasServiceBookForm = (permission: IPermissionACL): boolean => {
  const acl = employeeACL.employee.serviceBook;
  return (
    (permission.action === acl.create.action && permission.subject === acl.create.resource) ||
    (permission.action === acl.update.action && permission.subject === acl.update.resource)
  );
};
const hasActionForm = (permission: IPermissionACL): boolean => {
  const acl = employeeACL.employee.actions;
  return (
    (permission.action === acl.create.action && permission.subject === acl.create.resource) ||
    (permission.action === acl.update.action && permission.subject === acl.update.resource)
  );
};

const hasAchievementForm = (permission: IPermissionACL): boolean => {
  const acl = employeeACL.employee.achievement;
  return (
    (permission.action === acl.create.action && permission.subject === acl.create.resource) ||
    (permission.action === acl.update.action && permission.subject === acl.update.resource)
  );
};

const hasAddressForm = (permission: IPermissionACL): boolean => {
  const acl = employeeACL.employee.address;
  return (
    (permission.action === acl.create.action && permission.subject === acl.create.resource) ||
    (permission.action === acl.update.action && permission.subject === acl.update.resource)
  );
};

const hasDocumentForm = (permission: IPermissionACL): boolean => {
  const acl = employeeACL.employee.document;
  return (
    (permission.action === acl.create.action && permission.subject === acl.create.resource) ||
    (permission.action === acl.update.action && permission.subject === acl.update.resource)
  );
};
const hasFamilyForm = (permission: IPermissionACL): boolean => {
  const acl = employeeACL.employee.family;
  return (
    (permission.action === acl.create.action && permission.subject === acl.create.resource) ||
    (permission.action === acl.update.action && permission.subject === acl.update.resource)
  );
};
const hasBankInfoForm = (permission: IPermissionACL): boolean => {
  const acl = employeeACL.employee.bank;
  return (
    (permission.action === acl.create.action && permission.subject === acl.create.resource) ||
    (permission.action === acl.update.action && permission.subject === acl.update.resource)
  );
};
