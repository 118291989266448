// import axios from "axios";
import axios, { Axios, AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ENV } from "./ENV.config";
import { LocalStorageService } from "@services/utils/localsStorage.service";

const servicePortMap = {
  "attendance-service": "8001",
  "payroll-service": "8002",
};

const instance: AxiosInstance = axios.create({
  baseURL: ENV.ApiEndPoint,
});

export const CancelToken = axios.CancelToken;
export const ApiSource = CancelToken.source();

instance.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<any> => {
    const accessToken: string | null = await LocalStorageService.get("accessToken");

    if (accessToken && config?.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers.baseurl = ENV.ApiEndPoint;
    }

    if (ENV.env === "development") {
      Object.entries(servicePortMap).forEach(([service, port]) => {
        if (config.url?.includes(service) && config?.baseURL?.includes("8000")) {
          config.baseURL = config.baseURL.replace("8000", port);
        }
      });
    }

    // config.signal = abortController.signal;
    config.cancelToken = ApiSource.token;
    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    if (error.response) {
      return Promise.reject(error);
    }
    return Promise.reject({
      success: false,
      payload: {},
      message: "server not responding",
      timestamp: new Date(),
    });
  }
);

instance.interceptors.response.use(
  (res: AxiosResponse): any => {
    if (res.data?.success) return res.data;

    return Promise.reject(res.data);
  },
  (error: AxiosError): Promise<AxiosError> => {
    // TODO:: temporary handle
    if (error.response?.status === 401) {
      const accessToken: string | null = LocalStorageService.get("accessToken");

      if (accessToken) {
        LocalStorageService.clear();
        window.location.href = "/";
      }
    }

    if (error.response) {
      return Promise.reject(error.response?.data);
    } else {
      return Promise.reject({
        status: 404,
        message: "Server not responding",
        payload: {},
      });
    }
  }
);

export const apiIns: Axios = instance;
