import { IPayrollSetupACL } from "@acl/acl.interfaces";
import { commonFieldAcl } from "@acl/modules";

export const payrollSetupAcl: IPayrollSetupACL = {
  parent: { action: "pay-schedules", resource: "pay-schedules" },
  payrollSetup: {
    ...commonFieldAcl("pay-schedules"),
    updateStatus: { action: "updateStatus", resource: "pay-schedules" },
    policyCreate: { action: "store", resource: "pay-schedule-policies" },
    policyShow: { action: "findOne", resource: "pay-schedule-policies" },
  },
};
