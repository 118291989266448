import Logo from "@assets/images/rysenova-logo-v7_720.png";
import { AuthService } from "@services/apis/Auth.service";

import ability from "@acl/ability";
import { permissionMapping, processingMenuPermission } from "@acl/utils";
import VerificationBg from "@assets/images/verificationBg.svg";
import { LocalStorageService } from "@services/utils/localsStorage.service";
import { useAuth } from "context/auth";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DASHBOARD, LOGIN, MYSPACE, ONBOARD, WORKSPACE, } from "router/path-name.route";
import { Constant } from "utils/constant";

const AccountImpersonate = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { setUser, setPermissions, authenticate } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      handleImpersonate();
    }
  }, [token]);

  const handleImpersonate = () => {
    AuthService.userInfo({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setUser(res?.payload || []);
        if (res?.header?.permissions) {
          const mappingPermissions = permissionMapping(processingMenuPermission(res?.header?.permissions));
          ability.update(mappingPermissions);
          setPermissions(mappingPermissions);
        }
        authenticate(token, () => {
          if (res?.payload?.has_onboarded !== undefined && !res?.payload?.has_onboarded) {
            navigate(
              ONBOARD +
                "/" +
                (res.payload.on_boarding == Constant.INITIAL_ONBOARD.BASIC_INFO
                  ? res.payload.on_boarding
                  : Constant.INITIAL_ONBOARD.STEP + "/" + res.payload.on_boarding)
            );
            return;
          }
          if (res.payload?.is_employee) {
            navigate(DASHBOARD + "/" + MYSPACE);
            return;
          }
          navigate(DASHBOARD + "/" + WORKSPACE);
        });
      })
      .catch(() => {
        LocalStorageService.delete("accessToken");
        navigate(LOGIN);
      });
  };

  return (
    <div
      className="flex justify-center items-center w-screen h-screen bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${VerificationBg})` }}
    >
      <div>
        <img src={Logo} alt="" className="w-[225px] h-auto" />
        <h6 className="text-focus-in font-inter mt-3 uppercase text-xs tracking-[5.3px]">Powered By KUIPERZ</h6>
      </div>
    </div>
  );
};

export default AccountImpersonate;
