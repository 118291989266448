import assetsModule from "./assets.en.json";
import attendanceModule from "./attendance.en.json";
import authentication from "./authentication.en.json";
import dashboardTranslation from "./dashboard.en.json";
import EnEmployeeTranslation from "./employee.en.json";
import EnglishTranslation from "./en.json";
import EnLabel from "./label.en.json";
import leaveModule from "./leave.en.json";
import payrolls from "./Payrolls/payrolls.en.json";
import placeholder from "./placeholder.en.json";
import reportsModule from "./reports.en.json";
import commonENTranslation from "./response-message.en.json";
import scheduleModule from "./schedule.en.json";
import EnAccountSetting from "./settings/account-setting.en.json";
import aclModule from "./settings/acl.en.json";
import bankInfo from "./settings/bank-info.en.json";
import facilitiesModule from "./settings/facilities.en.json";
import hrManagement from "./settings/hr-management.en.json";
import EnSystemSettings from "./settings/system-setting.en.json";
import EnSidebarTranslation from "./sidebar.en.json";
import validationMessage from "./validation.en.json";
// Management
export const en = {
  ...EnglishTranslation,
  ...EnSidebarTranslation,
  ...EnEmployeeTranslation,
  ...EnAccountSetting,
  ...EnLabel,
  ...EnSystemSettings,
  ...placeholder,
  ...commonENTranslation,
  ...attendanceModule,
  ...leaveModule,
  ...scheduleModule,
  ...assetsModule,
  ...bankInfo,
  ...facilitiesModule,
  ...aclModule,
  ...validationMessage,
  ...reportsModule,
  ...dashboardTranslation,
  ...hrManagement,
  ...authentication,
  ...payrolls,
};
