import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService } from "@services/apis/Auth.service";
import { ButtonLoader } from "@services/utils/preloader.service";
import { ToastService } from "@services/utils/toastr.service";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const schema = yup.object().shape({
  organization_name: yup.string().required("Organization name is required!"),
});
const defaultValue = {
  organization_name: "",
};

const Organization = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    setIsLoading(true);
    AuthService.findOrganization(watch("organization_name"))
      .then((res) => {
        const response = res.payload;
        window.location.href = response?.frontend_url;
      })
      .catch((error) => {
        ToastService.error(error.message);
        setError("organization_name", {
          type: "validate",
          message: error.message,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="flex justify-center my-auto items-center sm:h-[500px] xs:h-auto sm:flex sm:justify-center lg:max-h-screen h-[100vh]">
      <div className="">
        <h3 className="font-inter text-lg mb-2">
          Welcome to <span className="text-primary-600 mb-4">Rysenova</span>
        </h3>
        <h3 className="font-inter text-sm">
          <span className="block capitalize text-primary-600 text-6xl mb-4">Find your Organization</span>
          Please provide your Organization name
        </h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            classNames="w-128 mb-3"
            label="Organization Name"
            placeholder="Enter organization name"
            registerProperty={register("organization_name")}
            errorText={errors?.organization_name?.message}
            staticText={import.meta.env?.VITE_BASE_APP_URL || ".rysenova.app"}
          />
          <Button
            className="px-6 py-3 mt-7 text-center flex justify-center rounded-[8px]"
            width="full"
            color="primary"
            variant="fill"
            type="submit"
            disable={isLoading}
          >
            {isLoading ? <ButtonLoader /> : "Submit"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Organization;
