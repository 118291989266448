import { IPayroll } from "@interfaces/payroll/disburse.interface";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUserInfo {
  payrollEditDrawer: boolean;
  payroll: IPayroll;
}

const initialState: IUserInfo = {
  payrollEditDrawer: false,
  payroll: {},
};

export const disbursementSlice = createSlice({
  name: "disbursement",
  initialState,
  reducers: {
    setPayroll: (state: any, action: PayloadAction<any>) => {
      state.payroll = action.payload;
    },
    setPayrollEditDrawer: (state: any, action: PayloadAction<any>) => {
      state.payrollEditDrawer = action.payload;
    },
  },
});

export const { setPayroll, setPayrollEditDrawer } = disbursementSlice.actions;

export default disbursementSlice.reducer;
