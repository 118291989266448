import Logo from "@assets/images/rysenova-logo-v7_720.png";
import VerificationBg from "@assets/images/verificationBg.svg";
import { useEffect } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

import { delay } from "utils";

const GlobalVerification = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const type = searchParams.get("type");
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      accountVerification();
    }
  }, [token, type]);

  const accountVerification = async () => {
    if (!token) return;

    await delay(2500);

    if (type === "email-update") {
      navigate({
        pathname: "/update-email",
        search: createSearchParams({
          token: token,
          type: type,
        })?.toString(),
      });
    } else {
      navigate({
        pathname: "/set-password",
        search: createSearchParams({
          token: token,
          type: type,
        })?.toString(),
      });
    }
  };

  return (
    <div
      className="flex justify-center items-center w-screen h-screen bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${VerificationBg})` }}
    >
      <div>
        <img src={Logo} alt="" className="w-[225px] h-auto" />
        <h6 className="text-focus-in font-inter mt-3 uppercase text-xs tracking-[5.3px]">Powered By KUIPERZ</h6>
      </div>
    </div>
  );
};

export default GlobalVerification;
