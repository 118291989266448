import { defaultEmployeeAcl, lineManagerAcl } from "@acl/modules/employee.data";
import { payrollHubAcl } from "@acl/modules/Payroll/Payroll/payrollhub.data";

import { salaryAcl } from "@acl/modules/Payroll/Payroll/salary.data";
import { payrollSetupAcl } from "@acl/modules/Payroll/Settings/payrollSetup.data";
import { salarySettingAcl } from "@acl/modules/Payroll/Settings/salary.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
  PAY_SCHEDULE_FORM,
  PAYROLL,
  PAYROLL_HUB,
  PAYROLL_SETUP,
  PAYROLLS,
  PAYSLIPS,
  REQUESTS,
  SALARY,
  SALARY_COMPONENTS,
  SALARY_INFO,
  SALARY_STRUCTURE,
  SETTINGS_SALARY,
} from "./path-name.route";

export const payrollRoutes: IRouteList[] = [
  // ------------for dashboard payroll-----------
  {
    path: PAYROLL + "/",
    component: lazy(() => import("@pages/Payroll/Salary/Salary.tsx")),
    meta: salaryAcl.parent,
    children: [
      {
        path: SALARY,
        component: lazy(() => import("@pages/Payroll/Salary/Tab/EmploySalaries/EmploySalaries")),
        meta: salaryAcl.employeeSalary,
      },
      {
        path: PAYSLIPS,
        component: lazy(() => import("@pages/Payroll/Salary/Tab/Payslips/EmployPayslips")),
        meta: defaultEmployeeAcl,
      },
      {
        path: SALARY_INFO,
        component: lazy(() => import("@pages/Payroll/Salary/Tab/EmploySalaryInfo/EmploySalaryInfo")),
        meta: defaultEmployeeAcl,
      },
      {
        path: REQUESTS,
        component: lazy(() => import("@pages/Payroll/Salary/Tab/Adjustment/PayrollAdjustment")),
        meta: [salaryAcl.request, lineManagerAcl, defaultEmployeeAcl],
      },
    ],
  },
  {
    path: PAYROLL + "/" + SALARY + "/details",
    component: lazy(() => import("@pages/Payroll/Salary/Tab/EmploySalaries/Components/EmploySalaryDetails")),
    meta: salaryAcl.employeeSalary?.update,
  },
  {
    path: PAYROLL + "/" + SALARY + "/assign",
    component: lazy(() => import("@pages/Payroll/Salary/Tab/EmploySalaries/Components/SalaryAssign")),
    meta: salaryAcl.employeeSalary?.create,
  },

  {
    path: PAYROLL + "/" + PAYSLIPS + "/details",
    component: lazy(() => import("@pages/Payroll/Salary/Tab/Payslips/Componnents/EmployPayslipDetails")),
    meta: defaultEmployeeAcl,
  },

  //................payroll hub...........
  {
    path: PAYROLL_HUB + "/",
    component: lazy(() => import("@pages/Payroll/PayrollHub/PayrollHub")),
    meta: payrollHubAcl.parent,
    children: [
      {
        path: PAYROLLS,
        component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payrolls/Payrolls")),
        meta: payrollHubAcl.payrolls,
      },
      {
        path: PAYSLIPS,
        component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payslips/Payslips")),
        meta: payrollHubAcl.payslips,
      },
    ],
  },

  {
    path: PAYROLL_HUB + "/" + PAYSLIPS + "/details",
    component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payslips/Components/PayslipDetails")),
    meta: payrollHubAcl.payslips?.findOne,
  },

  {
    path: `${PAYROLL_HUB}/${PAYROLLS}/details/:payrollId`,
    component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payrolls/PayrollDetails/PayrollDetailsTab")),
    meta: payrollHubAcl.parent,
    children: [
      {
        path: "salary-disbursement", // Child route 1
        component: lazy(
          () => import("@pages/Payroll/PayrollHub/Tab/Payrolls/PayrollDetails/Screen/SalaryDisbursement")
        ),
        meta: payrollHubAcl.payrolls.disbursement,
      },
      {
        path: "settings", // Child route 2
        component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payrolls/components/AttPolicySettings")),
        meta: payrollHubAcl.payrolls.disbursement,
      },
    ],
  },

  {
    path: `${PAYROLL_HUB}/${PAYROLLS}/:payrollId/salary-disbursement/:disburseGroupId`,
    component: lazy(() => import("@pages/Payroll/PayrollHub/Tab/Payrolls/PayrollDetails/Screen/DisburseGroupDetails")),
  },

  // ----------------for settings payroll-----------
  {
    path: SETTINGS_SALARY,
    component: lazy(() => import("@pages/Settings/PayrollSettings/SalarySettings/Salary")),
    meta: salarySettingAcl.parent,
    children: [
      {
        path: SALARY_COMPONENTS,
        component: lazy(
          () => import("@pages/Settings/PayrollSettings/SalarySettings/SalaryComponents/SalaryComponents")
        ),
        meta: salarySettingAcl.components,
      },
      {
        path: SALARY_STRUCTURE,
        component: lazy(() => import("@pages/Settings/PayrollSettings/SalarySettings/SalaryStructure/SalaryStructure")),
        meta: salarySettingAcl.salaryStructure,
      },
    ],
  },

  // ......pay Schedule module
  {
    path: PAYROLL_SETUP,
    component: lazy(() => import("@pages/Settings/PayrollSettings/PayrollSetup/PayrollSetup")),
    meta: payrollSetupAcl.parent,
    children: [
      {
        path: PAYROLL_SETUP,
        component: lazy(() => import("@pages/Settings/PayrollSettings/PayrollSetup/Tab/PaySchedules/PaySchedules")),
        meta: payrollSetupAcl.payrollSetup,
      },
    ],
  },

  {
    path: PAY_SCHEDULE_FORM,
    component: lazy(
      () =>
        import("@pages/Settings/PayrollSettings/PayrollSetup/Tab/PaySchedules/Components/Steps/PayScheduleFormLayout")
    ),
    meta: payrollSetupAcl.parent,
    children: [
      { path: "*", redirect: "general" },
      {
        path: "general",
        component: lazy(
          () => import("@pages/Settings/PayrollSettings/PayrollSetup/Tab/PaySchedules/Components/Steps/GeneralForm")
        ),
        meta: [payrollSetupAcl?.payrollSetup?.create, payrollSetupAcl?.payrollSetup?.update],
      },

      {
        path: "attendance-policy",
        component: lazy(
          () =>
            import(
              "@pages/Settings/PayrollSettings/PayrollSetup/Tab/PaySchedules/Components/Steps/AttendancePolicyForm"
            )
        ),
        meta: [payrollSetupAcl.payrollSetup.policyCreate, payrollSetupAcl.payrollSetup.policyShow],
      },
    ],
  },
];
