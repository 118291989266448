import Button from "@components/Button/Button";
import Icon from "@components/Icon/Icon";
import NotificationSke from "@components/Skeleton/NotificationSke";
import { INotification, INotificationMeta } from "@interfaces/notification.interface";
import { NotificationService } from "@services/apis/Notification.service";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  ATTENDANCE,
  ATTENDANCE_CHECK_IN,
  EMPLOYEES,
  GROUPS,
  LEAVE_APPLICATION,
  LEAVE_MANAGEMENT,
  OWN_APPLICATION,
  PENDING_REQUEST,
  TEAMS,
} from "router/path-name.route";
import { addSearchParams, isArray, isObject } from "utils";
import { systemDateTime } from "utils/system.utils";

interface INotificationsData {
  [key: string]: INotificationMeta;
}

interface INotifications {
  notification: INotification[];
  setNotification: Function;
  isLoading?: boolean;
  setIsLoading?: Function;
  filter: "all" | "unread";
  setFilter: Function;
  startSkeleton?: boolean;
  stopSkeleton?: Function;
  hasLoader?: boolean;
}

interface INotificationItem {
  metaOBJ: any;
  item: INotification;
}

const Notifications = ({
  notification,
  setNotification,
  isLoading,
  setIsLoading,
  filter,
  setFilter,
  startSkeleton,
  stopSkeleton,
  hasLoader = true,
}: INotifications) => {
  const [hoveredNotification, setHoveredNotification] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const notificationsData: INotificationsData = {
    LEAVE_APPLICATION_APPROVER_PIPELINE: {
      route: LEAVE_MANAGEMENT + "/" + LEAVE_APPLICATION,
      icon: "calendar_today",
      searchQuery: { details: "application_id", leave_id: "application_leave_id" },
    },
    LEAVE_APPLICATION_ACTING_EMPLOYEE: {
      route: LEAVE_MANAGEMENT + "/" + OWN_APPLICATION,
      icon: "calendar_today",
      readOnly: true,
    },
    LEAVE_APPLICATION_NOTIFY_EMPLOYEE: {
      route: LEAVE_MANAGEMENT + "/" + OWN_APPLICATION,
      icon: "calendar_today",
      searchQuery: { details: "application_id", leave_id: "application_leave_id" },
    },
    ATTENDANCE_ADJUSTMENT_APPROVER_PIPELINE: {
      route: ATTENDANCE + "/" + PENDING_REQUEST,
      icon: "how_to_reg",
      searchQuery: { details: "attendance_adjustment_id" },
    },
    ATTENDANCE_ADJUSTMENT_NOTIFY_EMPLOYEE: {
      route: ATTENDANCE + "/" + ATTENDANCE_CHECK_IN,
      icon: "how_to_reg",
      searchQuery: { details: "attendance_adjustment_id" },
    },
    TEAM_ASSIGN_MEMBER_STORE: {
      route: EMPLOYEES + "/" + TEAMS,
      icon: "group",
      searchQuery: { notify: "team_id" },
    },
    TEAM_ASSIGN_MEMBER_UPDATE: {
      route: EMPLOYEES + "/" + TEAMS,
      icon: "group",
      searchQuery: { team: "team_id" },
    },
    TEAM_REMOVE_MEMBER: {
      route: EMPLOYEES + "/" + TEAMS,
      icon: "group",
      readOnly: true,
    },
    GROUP_ASSIGN_MEMBER_STORE: {
      route: EMPLOYEES + "/" + GROUPS,
      icon: "groups",
      searchQuery: { notify: "group_id" },
    },
    GROUP_ASSIGN_MEMBER_UPDATE: {
      route: EMPLOYEES + "/" + GROUPS,
      icon: "groups",
      searchQuery: { group: "group_id" },
    },
    GROUP_REMOVE_MEMBER: {
      route: EMPLOYEES + "/" + GROUPS,
      icon: "groups",
      readOnly: true,
    },
  };

  // useEffect(() => {
  //   getNotification && getNotification(notification);
  // }, [notification]);

  // const getAllNotification = () => {
  //   NotificationService.getAll().then(({ payload }) => {
  //     setNotification(payload);
  //   });
  // };

  const markAsRead = (id: string) => {
    setLoader(true);
    NotificationService.batchRead({ ids: [id] })
      .then(({ payload }) => {
        setNotification((state) =>
          state.map((item) => {
            if (item.id == id) {
              item = payload[0];
            }
            return item;
          })
        );
      })
      .finally(() => {
        setLoader(false);
        setIsLoading && setIsLoading(true);
      });
  };

  const markAllAsRead = () => {
    setLoader(true);
    const ids = notification.filter((item) => !item.read_at).map((item) => item.id);

    NotificationService.batchRead({ ids })
      .then(({ payload }) => {
        const readIds = {};
        payload.map((item: any) => {
          return (readIds[item.id] = item);
        });

        if (filter == "all") {
          setNotification((state: any) => {
            return state.map((item: any) => {
              if (readIds[item.id]) {
                return readIds[item.id];
              }
              return item;
            });
          });
        } else {
          setNotification([]);
        }
      })
      .finally(() => {
        setLoader(false);
        setIsLoading && setIsLoading(true);
      });
  };

  const handleSearchQuery = (item, metaOBJ) => {
    if (isObject(metaOBJ?.searchQuery)) {
      addSearchParams(metaOBJ.searchQuery);

      const newOBJ = {};

      for (const key in metaOBJ.searchQuery) {
        newOBJ[key] = item?.data[metaOBJ.searchQuery[key]];
      }

      return addSearchParams(newOBJ, false).search;
    }
    return "";
  };

  return (
    <div className="px-4  mx:-6">
      <div className="mt-4 text-sm font-semibold text-neutral-800">Notifications</div>
      <div className="md:w-[474px] w-[340px] mx-auto">
        <div className="flex justify-between items-center mb-3 bg-white">
          <div className="">
            <span
              onClick={() => setFilter("all")}
              className={`mr-4 pb-2 text-sm transition-colors duration-500 ${
                filter == "all" ? "text-primary-500 font-semibold border-b-2 border-primary-500" : "text-neutral-300"
              } cursor-pointer`}
            >
              All
            </span>
            <span
              onClick={() => setFilter("unread")}
              className={`pb-2 text-sm transition-colors duration-500 ${
                filter == "unread" ? "text-primary-500 font-semibold border-b-2 border-primary-500" : "text-neutral-300"
              } cursor-pointer`}
            >
              Unread
            </span>
          </div>
          <Button
            disable={!isArray(notification.filter((item) => !item.read_at))}
            onClick={() => markAllAsRead()}
            variant="shade"
            size="xs"
          >
            Mark all as Read
          </Button>
        </div>
        {/* {loader && hasLoader ? <ButtonLoader frontColor="#3899F4" /> : null} */}

        {/* <NotificationSke /> */}

        <ul>
          {startSkeleton ? (
            <NotificationSke />
          ) : (
            // loader && hasLoader ? (
            //   <Spinner />
            // ) :
            notification
              // .filter((item) => (filter == "all" ? item?.is_active : !item?.read_at))
              .map((item) => {
                const metaOBJ = notificationsData?.[item.meta_key];

                return (
                  <div
                    key={item?.id}
                    className={`${metaOBJ?.disable ? "pointer-events-none opacity-50" : ""}  ${
                      hoveredNotification == item.id ? "bg-primary-50" : ""
                    } transition-all duration-300`}
                    onMouseEnter={() => setHoveredNotification(item.id)}
                    onMouseLeave={() => setHoveredNotification(null)}
                  >
                    {metaOBJ?.readOnly ? (
                      <div
                        onClick={() => item?.read_at || markAsRead(item?.id)}
                        className="flex items-center justify-between p-3 cursor-pointer"
                      >
                        <NotificationItem metaOBJ={metaOBJ} item={item} />
                      </div>
                    ) : (
                      <Link
                        to={metaOBJ?.route + handleSearchQuery(item, metaOBJ)}
                        className={`flex items-center justify-between p-3 cursor-pointer`}
                        onClick={() => item?.read_at || markAsRead(item?.id)}
                        state={item}
                      >
                        <NotificationItem metaOBJ={metaOBJ} item={item} />
                      </Link>
                    )}
                  </div>
                );
              })
          )}
        </ul>
        {!isArray(notification) && !startSkeleton ? <h6 className="mt-6 text-center">No notification Found</h6> : null}
      </div>
    </div>
  );
};

const NotificationItem = ({ metaOBJ, item }: INotificationItem) => {
  return (
    <>
      <div className="flex gap-3 md:w-[98%] w-full">
        <Icon
          name={metaOBJ?.icon}
          variant="outlined"
          className={`text-xl ${item?.read_at ? "text-neutral-300" : "text-neutral-900"}`}
        />
        <div>
          <p className={`text-sm font-medium ${item?.read_at ? "text-neutral-400" : "text-neutral-900"}`}>
            {item?.title}
          </p>
          <span
            className={`mt-3 text-xs  ${
              !item?.read_at ? "font-semibold text-primary-500" : "font-normal text-neutral-500"
            }`}
          >
            {systemDateTime(item?.created_at)}
          </span>
        </div>
      </div>
      {!item?.read_at ? <span className="w-3 h-3 bg-primary-500 rounded-full"></span> : null}
    </>
  );
};

export default Notifications;
