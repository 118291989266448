import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ReportService } from "@services/apis/Report.service";

interface IReportFilter {
  pdfPreConfigModal: boolean;
  divisions: any;
  departments: any;
  locations: any;
  employmentTypes: any;
  designation: any;
  teams: any;
  groups: any;
  grades: any;
  totalAttendanceReport: number;
  totalEmployeeLogReport: number;
  totalEmployeeSummeryReport: number;
  totalLeaveApplicationReport: number;
  totalLeaveBalanceReport: number;
  totalReport: number;
  isLoading: boolean;
  exportBtnTriggered: boolean;
}

const initialState: IReportFilter = {
  pdfPreConfigModal: false,
  divisions: [],
  departments: [],
  employmentTypes: [],
  designation: [],
  locations: [],
  teams: [],
  groups: [],
  grades: [],
  totalAttendanceReport: 0,
  totalEmployeeLogReport: 0,
  totalEmployeeSummeryReport: 0,
  totalLeaveApplicationReport: 0,
  totalLeaveBalanceReport: 0,
  totalReport: 0,
  isLoading: false,
  exportBtnTriggered: false,
};

export const fetchReportFilterOption = createAsyncThunk("report-filter/options", () => {
  const response = ReportService.getReportOptions();
  return response;
});

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    exportTriggerAction: (state) => {
      state.exportBtnTriggered = true;
    },
    exportResetAction: (state) => {
      state.exportBtnTriggered = false;
    },

    setPdfPreConfigModal: (state, action: PayloadAction<boolean>) => {
      state.pdfPreConfigModal = action.payload;
    },

    setTotalAttendanceReport: (state, action: PayloadAction<number>) => {
      state.totalAttendanceReport = action.payload;
    },

    setTotalEmployeeLogReport: (state, action: PayloadAction<number>) => {
      state.totalEmployeeLogReport = action.payload;
    },

    setTotalEmployeeSummeryReport: (state, action: PayloadAction<number>) => {
      state.totalEmployeeSummeryReport = action.payload;
    },

    setTotalLeaveApplicationReport: (state, action: PayloadAction<number>) => {
      state.totalLeaveApplicationReport = action.payload;
    },

    setTotalLeaveBalanceReport: (state, action: PayloadAction<number>) => {
      state.totalLeaveBalanceReport = action.payload;
    },

    setTotalReport: (state, action: PayloadAction<number>) => {
      state.totalReport = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportFilterOption.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchReportFilterOption.fulfilled, (state, { payload: { payload: res } }) => {
        state.isLoading = false;
        state.divisions = res?.divisions || [];
        state.employmentTypes = res?.employment_types || [];
        state.teams = res?.teams || [];
        state.grades = res?.grades || [];
        state.groups = res?.groups || [];
        state.locations = res?.locations || [];

        const designation: any = [];
        res?.grades?.map((item) => {
          designation.push(...item.designations);
        });

        state.designation = designation || [];

        return state;
      })
      .addCase(fetchReportFilterOption.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setPdfPreConfigModal,
  setTotalAttendanceReport,
  setTotalEmployeeLogReport,
  setTotalEmployeeSummeryReport,
  setTotalLeaveApplicationReport,
  setTotalLeaveBalanceReport,
  setTotalReport,
  exportTriggerAction,
  exportResetAction,
} = reportSlice.actions;

export default reportSlice.reducer;
