import { isAfter, isValid, startOfDay } from "date-fns";
import { deepCopy } from "utils";
import { Constant } from "utils/constant";
import { attendancePullStartDate } from "utils/date.utils";

export const isShiftOverlapping = (shift1: any, shift2: any) => {
  const startTime1 = shift1.start_time;
  const endTime1 = shift1.end_time;
  const startTime2 = shift2.start_time;
  const endTime2 = shift2.end_time;

  // Case 1: Both shifts start and end on the same day
  if (startTime1 <= endTime1 && startTime2 <= endTime2) {
    return startTime1 < endTime2 && startTime2 < endTime1;
  }

  // Case 2: Shift 1 spans across multiple days
  if (startTime1 > endTime1 && startTime2 <= endTime2) {
    return startTime1 < endTime2 || startTime2 < endTime1;
  }

  // Case 3: Shift 2 spans across multiple days
  if (startTime2 > endTime2 && startTime1 <= endTime1) {
    return startTime2 < endTime1 || startTime1 < endTime2;
  }

  // Case 4: Both shifts span across multiple days
  return true;
};

export const reorderArray = (arr: any[], matchKey: string, currentId: string) => {
  // Find the index of the object with the current ID

  const modArr = deepCopy(arr);

  const currentIndex = modArr
    ?.sort((d1, d2) => d1[matchKey] - d2[matchKey])
    ?.findIndex((obj) => obj[matchKey] === currentId);

  if (currentIndex === -1) {
    // If current ID is not found, return the original array
    return modArr;
  }

  // console.log(arr?.sort((item) => item[matchKey]));

  // Slice the array into two parts: from the currentIndex to the end, and from the beginning to the currentIndex (exclusive)
  const part1 = modArr?.slice(currentIndex);
  const part2 = modArr?.slice(0, currentIndex);

  // Concatenate the two sliced arrays to create the reordered array
  return [...part1, ...part2];
};

export const areArraysDifferent = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return true; // If lengths are different, arrays are different
  }

  // Convert each object to a string and compare arrays
  const arr1Strings = arr1.map((obj) => JSON.stringify(obj));
  const arr2Strings = arr2.map((obj) => JSON.stringify(obj));

  // Check if every element in arr1 is present in arr2
  return !arr1Strings.every((item, index) => item === arr2Strings[index]);
};

export const calculateDateRange = (watch: any, setValue: Function) => {
  const rosterType = watch("type")?.value;
  const selectedStartDate: any = watch("effective_start_date");
  const selectedMonth = watch("month")?.id || null;

  // if (!isValid(watch("effective_start_date"))) return;

  if (rosterType && (selectedMonth || selectedStartDate instanceof Date)) {
    const startDate = new Date(selectedStartDate);

    let endDate;

    if (rosterType === Constant.MONTHLY && selectedMonth) {
      const month = +selectedMonth;
      const year = watch("year").value;
      const attendancePullDate = attendancePullStartDate ? startOfDay(attendancePullStartDate) : null;

      // Calculate the first day of the selected month
      let startDate = startOfDay(new Date(year, month - 1, 1));

      if (isValid(attendancePullDate) && isAfter(attendancePullDate, startDate)) {
        startDate = attendancePullDate;
      }

      // Calculate the last day of the selected month
      const endDate = new Date(year, month, 0);

      if (startDate instanceof Date && endDate instanceof Date) {
        setValue("effective_start_date", startDate);
        setValue("end_date", endDate);
      }
    } else if (rosterType === Constant.WEEKLY) {
      // Calculate the end date as 6 days after the start date for Weekly
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
    } else if (rosterType === Constant.FIXED) {
      // endDate = "will continue";
      setValue("effective_start_date", startDate);
      // setValue("end_date", "will continue");
    }

    if (endDate instanceof Date) {
      setValue("effective_start_date", startDate);
      setValue("end_date", endDate);
    }
  }
};
